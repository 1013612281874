import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalState";
import H2 from "./Content/H2";
import TextField from "./Form/TextField";
import RegistrationHeader from "./RegistrationHeader";

export default function HomeContent4() {

    const { setBaseInfo, baseInfo } = useContext(GlobalContext);
    const getMail = () => {
        if (baseInfo && "email" in baseInfo) {
            return baseInfo.email;
        } else {
            return ""
        }
    }
    const email = getMail();

    return (
        <div className="py-16 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden text-left">
            <div className="max-w-max lg:max-w-7xl mx-auto px-2">
                <div className="relative z-10 mb-8 md:mb-2 md:px-6">

                    <RegistrationHeader
                        active={3}
                        title="Finanzierungsantrag"
                    />

                    <H2>
                        Der letzte Schritt zum Finanzierungsantrag
                    </H2>
                </div>
                <div className="relative">

                    <div className="relative md:bg-white md:p-6">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                            <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none ">
                                <TextField
                                    id="name"
                                    label="Name"
                                    type="text"
                                    placeholder="Mustermann"
                                    autoFocus={true}
                                />
                                <TextField
                                    id="adresse"
                                    label="Adresse"
                                    type="text"
                                    placeholder="Dorfstrasse 42"
                                />
                                <TextField
                                    id="telefon"
                                    label="Telefon"
                                    type="tel"
                                    placeholder="XXX XXX XX XX"
                                />
                                <TextField
                                    id="geburtsdatum"
                                    label="Geburtsdatum"
                                    type="date"
                                    placeholder="XX.XX.XXXX"
                                />
                            </div>
                            <div className="mt-6 text-gray-500 lg:mt-0">
                                <TextField
                                    id="vorname"
                                    label="Vorname"
                                    type="text"
                                    placeholder="Hans"
                                />
                                <TextField
                                    id="plz"
                                    label="PLZ"
                                    type="text"
                                    placeholder="XXXX"
                                />
                                <TextField
                                    id="ort"
                                    label="Ort"
                                    type="text"
                                    placeholder="Luzern"
                                />
                                <TextField
                                    id="email"
                                    label="E-Mail"
                                    type="email"
                                    placeholder="hans@mustermann.ch"
                                    value={email}
                                />
                            </div>
                        </div>

                        <div className="mt-24 flex justify-center">
                            <a
                                href="/projekte"
                                className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white bg-theme_green hover:bg-theme_blue transition-colors font-bold"
                            >
                                Registrieren abschliessen
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}