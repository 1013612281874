import React from 'react';

import TopBar from '../components/TopBar';
import Base from '../layout/base';
import HomeContent4 from '../components/HomeContent4';

function App() {
  return (
    <Base>
      <div className="App">
        <TopBar />
        <HomeContent4 />
      </div>
    </Base>
  );
}

export default App;
